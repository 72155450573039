export const URL_HOME = "/start";
export const URL_DETAIL = "/bild";
export const URL_CONTACT = "/kontakt";
export const URL_MEDIA = "/medienberichte";

export const URL_ABOUT = "/ueber-das-projekt";
export const URL_BLOG = "/blog";
export const URL_TOURS = "/galerien";
export const URL_TIMELINE = "/zeitachse";
export const URL_SEARCH = "/suchen";
export const URL_MITMACHEN = "/mitmachen";
export const URL_MAP = "/karte";
export const URL_NEWSFEED = "/neuigkeiten";
export const URL_DATENSCHUTZ = "/datenschutz";
export const URL_FAQ = "/faq";
export const URL_EVENT = "/events";
export const URL_BEZIRK = "/bezirke";

const hostname = window && window.location && window.location.hostname;
let backendHost, imagePath;
if (hostname === 'localhost') {
  backendHost = 'https://test.zeitenspruenge.at/backend/api.php';
  //backendHost = 'http://localhost:8888/zeitenspruenge-backend/api.php';
} else {
  backendHost = 'https://' + hostname + '/backend/api.php';
}
export let IMAGE_PATH_BASE = '/images';
if(hostname === 'test.zeitenspruenge.at' || hostname.includes('localhost')) {
  IMAGE_PATH_BASE = 'https://www.zeitenspruenge.at/images';
}

export const IMAGE_PATH = IMAGE_PATH_BASE + '/medium';
export const IMAGE_PATH_SMALL = IMAGE_PATH_BASE + '/small';


export const BACKEND_BASE_URL = backendHost;
//export const BACKEND_BASE_URL = 'http://zeitenspruenge.bplaced.net/backend/api.php';
export const BACKEND_COMPARISON_URL = BACKEND_BASE_URL + '/comparison';
export const BACKEND_BLOGENTRY_URL = BACKEND_BASE_URL + '/blogentry';
export const BACKEND_DISTRICT_URL = BACKEND_BASE_URL + '/district';
export const BACKEND_IMAGE_URL = BACKEND_BASE_URL + '/image';
export const BACKEND_TOUR_URL = BACKEND_BASE_URL + '/tour';
export const BACKEND_EVENT_URL = BACKEND_BASE_URL + '/event';
export const BACKEND_LOGIN_URL = BACKEND_BASE_URL + '/login';

export const GERMAN_DATE_FORMAT = 'DD.MM.YYYY';

export const BREAKPOINTS = { sm: 768 };


export const googleAnalyticsTrackingId = 'UA-136658195-1';//bplaced 'UA-135436585-1';

export const districts = [
  {
    url: "innere-stadt",
    district_number: 1,
    name: "Innere Stadt",
    area: 3.01,
    population: 16306
  },
  {
    url: "leopoldstadt",
    district_number: 2,
    name: "Leopoldstadt",
    area: 19.24,
    population: 104946
  },
  {
    url: "landstrasse",
    district_number: 3,
    name: "Landstraße",
    area: 7.39,
    population: 91745
  },
  {


    url: "wieden",
    district_number: 4,
    name: "Wieden",
    area: 1.8,
    population: 33263
  },
  {

    url: "margareten",
    district_number: 5,
    name: "Margareten",
  },
  {

    url: "mariahilf",
    district_number: 6,
    name: "Mariahilf",
  },
  {


    url: "neubau",
    district_number: 7,
    name: "Neubau",
  },
  {


    url: "josefstadt",
    district_number: 8,
    name: "Josefstadt",
  },
  {

    url: "alsergrund",
    district_number: 9,
    name: "Alsergrund",
  },
  {
    url: "favoriten",
    district_number: 10,
    name: "Favoriten",
  },
  {
    url: "simmering",
    district_number: 11,
    name: "Simmering",
  },
  {

    url: "meidling",
    district_number: 12,
    name: "Meidling",
  },
  {

    url: "hietzing",
    district_number: 13,
    name: "Hietzing",
  },
  {

    url: "penzing",
    district_number: 14,
    name: "Penzing",
  },
  {
    url: "rudolfsheim-fuenfhaus",
    district_number: 15,
    name: "Rudolfsheim-Fünfhaus",
  },
  {

    url: "ottakring",
    district_number: 16,
    name: "Ottakring",
  },
  {

    url: "hernals",
    district_number: 17,
    name: "Hernals",
  },
  {
    url: "waehring",
    district_number: 18,
    name: "Währing",
  },
  {
    url: "doebling",
    district_number: 19,
    name: "Döbling",
  },
  {

    url: "brigittenau",
    district_number: 20,
    name: "Brigittenau",
  },
  {

    url: "floridsdorf",
    district_number: 21,
    name: "Floridsdorf",
  },
  {

    url: "donaustadt",
    district_number: 22,
    name: "Donaustadt",
  },
  {
    url: "liesing",
    district_number: 23,
    name: "Liesing",
  }
];

export const months = {
  1: "Jänner",
  2: "Februar",
  3: "März",
  4: "April",
  5: "Mai",
  6: "Juni",
  7: "Juli",
  8: "August",
  9: "September",
  10: "Oktober",
  11: "November",
  12: "Dezember",



}; 