import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import NavLink from './NavLink';
import {
    URL_HOME,
    URL_MAP,
    URL_SEARCH,
    URL_BEZIRK,
    URL_CONTACT,
    URL_TOURS,
    URL_DETAIL,
    URL_TIMELINE,
    URL_NEWSFEED
} from '../common/constants.js';
import imgLogo from "../sources/images/zeitenspruenge logo-gruen.svg";

class Header extends Component {

    constructor() {
        super();

        this.state = {
            isTop: true,
            menuVisible: false
        };

        this.isLocationMap = this.isLocationMap.bind(this);
        this.isLocationHome = this.isLocationHome.bind(this);
        this.getHeaderClass = this.getHeaderClass.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.hideMenu = this.hideMenu.bind(this);

    }

    isLocationMap() {
        return this.props.location.pathname.indexOf(URL_MAP) >= 0;
    }

    isLocationHome() {
        return this.props.location.pathname === URL_HOME;
    }

    isLocationDetail() {
        return this.props.location.pathname.indexOf(URL_DETAIL) >= 0;
    }

    getHeaderClass() {
        if (this.isLocationHome()) {
            if (this.state.isTop && !this.state.menuVisible) {
                return 'fixed-top navbar-dark';
            } else {
                return 'fixed-top navbar-light shadow-sm';
            }

        } else if (this.isLocationMap()) {
            return 'navigation-map navbar-light shadow-sm';
        } else if (this.isLocationDetail()) {
            return 'fixed-top navbar-light shadow-sm hide-in-landscape';
        } else {
            return 'fixed-top navbar-light shadow-sm';
        }
    }


    toggleMenu() {
        var oldState = this.state.menuVisible;
        this.setState({
            menuVisible: !oldState
        });

    }

    hideMenu() {
        this.setState({
            menuVisible: false
        });
    }


    componentDidMount() {
        var that = this;
        document.addEventListener('scroll', (e) => {
            const isTop = window.scrollY <= 0; // //window.outerHeight - 200;
            if (isTop !== that.state.isTop) {
                that.setState({isTop});
            }
        });
    }


    render() {
        const submenu = (
            <div className="dropdown-menu show" aria-labelledby="navbarDropdownMenuLink">
                <a className="dropdown-item" href="#">Action</a>
                <a className="dropdown-item" href="#">Another action</a>
                <a className="dropdown-item" href="#">Something else here</a>
            </div>
        );
        return <div className={this.getHeaderClass()}>
            <nav className={`navbar transition-medium navbar-expand-lg ${this.getHeaderClass()}`}>
                <Link to={URL_HOME} onClick={() => this.hideMenu()} className="navbar-brand d-inline-flex">
                    Zeitensprünge Wien
                </Link>

                <button className="navbar-toggler" type="button" onClick={() => this.toggleMenu()}
                        data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={`collapse navbar-collapse ${this.state.menuVisible ? 'show' : ''}`}
                     id="navbarNavAltMarkup">
                    <ul className="navbar-nav mr-auto">


                        <NavLink to={URL_SEARCH} name="Suchen"/>
                        <NavLink to={URL_MAP} name="Karte"/>
                        <NavLink to={URL_BEZIRK} name="Bezirke" />

                        <NavLink to={URL_TOURS} name="Galerien"/>


                        <NavLink to={URL_TIMELINE} name="Zeitachse"/>
                        {/*<Link to={URL_NEWSFEED} onClick={()=>this.hideMenu()} className="nav-item nav-link">Newsfeed</Link>*/}

                    </ul>
                    <div className="navbar-nav">
                        <form className="form-inline float-right my-2 my-lg-0" action={URL_SEARCH}>
                            <input type="text" name="q"
                                   className={`input-search input-search-header pr-5 form-rounde-d form-control mr-sm-2 ${this.isLocationHome() && this.state.isTop && !this.state.menuVisible ? 'form-control-transparent' : ''}`}
                                   onChange={(e) => this.handleChange(e)}
                                   placeholder="Suchbegriff eingeben"
                                   aria-label="Suchbegriff eingeben"
                                   value={this.state.queryString}/>
                        </form>
                    </div>

                </div>
            </nav>

        </div>
    }
}

export default withRouter(Header);