import React, { Component } from 'react';
import { FaAngleUp } from 'react-icons/fa';

class ScrollToTopIcon extends Component {
    
  
    constructor(props){
      super(props);
      this.state = {
        isTop: true
      };
    }
    handleClick() {
      window.scrollTo(0,0);
    }

    componentDidMount() {
      var that = this;
      document.addEventListener('scroll', (e) => {
        const isTop = window.scrollY <=  100; // //window.outerHeight - 200;
        if (isTop !== that.state.isTop) {
            that.setState({ isTop });
        }
      });
    }
    render() {
      return <a className={`scroll-to-top-icon animated ${this.state.isTop ? 'd-none' : ''}`}><FaAngleUp onClick={() => this.handleClick()}/></a>;
    }
}

export default ScrollToTopIcon;