import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

//import { render } from 'react-snapshot';
import './index.css';
import Main from './Main/Main';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as serviceWorker from './serviceWorker';


ReactDOM.render(<Router>
       <Main />
  </Router>, document.getElementById('root'));
/*
render(<Router>
  <Main />
</Router>, document.getElementById('root'));
*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
