import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {
    URL_HOME,
    URL_MAP,
    URL_SEARCH,
    URL_CONTACT,
    URL_TOURS,
    URL_DETAIL,
    URL_TIMELINE,
    URL_NEWSFEED
} from '../common/constants.js';
import {FaArrowDown, FaChevronDown} from "react-icons/all";

class NavLink extends Component {

    render() {
        return (
            <li className={`nav-item ${this.props.submenu ? 'dropdown' : ''}`}>
                <Link to={this.state.to} onClick={() => this.hideMenu()}
                      className="nav-item nav-link">{this.state.name}
                    {
                        this.props.submenu && <FaChevronDown className="ml-1" />
                    }
                </Link>
                { this.props.submenu }
            </li>
        );

    }

    constructor(props) {
        super(props);
        this.state = {
            to: props.to,
            name: props.name,

        };
    }
}

export default withRouter(NavLink);