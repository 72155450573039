import React, { Component } from 'react';
import s from './LoadingSpinner.module.scss';

function LoadingSpinner() {
  return (
    <div className={s.loadingScreen}>
      <div className={s.ldsRing}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>  
  );
}

export default LoadingSpinner;